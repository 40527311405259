<script setup lang="ts">
import BrowserNotification from '@components/BrowserNotification.vue';
import Loader from '@components/Loader.vue';

import { useRoute } from 'vue-router';
import { useHead } from 'nuxt/app';
import { computed } from 'vue';
import { AppToasts, AppModal, AppTooltips, Footer } from '@mogelijk-technologies/ui-library';
import { useAuthStore } from '@stores/useAuthStore';

const route = useRoute();
const { isLoading } = toRefs(useAuthStore());

useHead(
  computed(() => ({
    meta: [
      {
        name: 'og:title',
        content: `${route.meta.title} | Mogelijk Vastgoedfinancieringen`,
      },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no',
      },
    ],
    titleTemplate: '%s | Mogelijk Vastgoedfinancieringen',
  })),
);

</script>

<template>
  <Head>
    <Title>{{ route.meta.title }}</Title>
    <Link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/favicons/apple-touch-icon.png"
    />
    <Link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/favicons/favicon-32x32.png"
    />
    <Link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/favicons/favicon-16x16.png"
    />
    <Link rel="manifest" href="/favicons/site.webmanifest" />
    <Meta name="msapplication-TileColor" content="#da532c" />
    <Meta name="theme-color" content="#ffffff" />
  </Head>
  <div id="mogelijk__ui-library">
    <div v-if="isLoading" class="flex min-h-screen flex-col min-w-screen items-center justify-center">
      <Loader />
    </div>
    <div v-else class="flex h-full flex-col">
      <BrowserNotification />
      <slot />
      <AppToasts />
      <AppModal />
      <AppTooltips />
      <div id="tooltip" />
      <div class="flex items-end">
        <Footer class="justify-self-end" />
      </div>
    </div>
  </div>
</template>
